import './Card.css'
import {Link} from "react-router-dom";

function Card ({name, job, aboutText, profilePic, showSocialMedia, showContactInfo, cardtext, border}) {

    const SocialMedia = showSocialMedia === true ? (
        <div>
            <a href="http://www.instagram.com/kingpauluss" rel="noreferrer" target="_blank"><img src={require('../../img/instagram.png')} alt="instagram-logo" height="30px" width="30px"/></a>
            <a href="https://snapchat.com/add/paul_m02" rel="noreferrer" target="_blank"><img src={require('../../img/snapchat.png')} alt="snapchat-logo" height="30px" width="30px"/></a>
            <a href="https://www.linkedin.com/in/paul-merget-9b264525a/" rel="noreferrer" target="_blank"><img src={require('../../img/linkedin.png')} alt="linkedin-logo" height="30px" width="30px"/></a>
            <a href="https://www.facebook.com/paul.merget.58/" rel="noreferrer" target="_blank"><img src={require('../../img/facebook.png')} alt="facebook-logo" height="30px" width="30px"/></a>
            <a href="https://bere.al/king_paulus" rel="noreferrer" target="_blank"><img src={require('../../img/bereal.png')} alt="BeReal-logo" height="30px" width="30px"/></a>
            <a href="https://steamcommunity.com/profiles/76561198273059032" rel="noreferrer" target="_blank"><img src={require('../../img/steam.png')} alt="Steam-logo" height="30px" width="30px"/></a>
        </div>
    ) : "";
    const contactInfo = showContactInfo === true ? (
        <span>
            <div>
                <Link to={"/whatsapp"}><img src={require('../../img/whatsapp.png')} alt="whatsapp-logo" height="33px" width="33px"/></Link>
                <Link to={"/github"}><img src={require('../../img/github.png')} alt="github-logo" height="33px" width="33px"/></Link>
                <Link to={"/telefon"}><img src={require('../../img/telefon.png')} alt="telefon-logo" height="30px" width="30px"/></Link>
                <Link to={"/email"}><img src={require('../../img/mail.png')} alt="mail-logo" height="30px" width="30px"/></Link>
            </div>
            <div>
                <button class="btn-3">
                    <a href={require('../../contact-information/Paul Merget.vcf')}>
                        Add to contacts
                    </a>
                </button>
            </div>
        </span>
    ) : "";

    const classList = [
        'Card',
        border ? `card-border` : null,
    ].filter(c => !!c).join(' ');
    return (
        <div class={classList}>
            <div class='upper-container'>
                <div class='image-container'>
                    <img src={profilePic} alt="User Profile" />
                </div>
            </div>

            <div class="lower-container">
                <h3> {name} </h3>
                <h4> {job} </h4>
                {aboutText}
                {SocialMedia}
                {contactInfo}
                <p> {cardtext} </p>
            </div>
        </div>
        )
}

export default Card
