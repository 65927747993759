import React, {useState} from 'react'
import './Codeblock.css'

function Codeblock ({subtext, item1,  item2,  item3,  item4}) {

    const listItem1 = typeof item1 !== 'undefined' ? (
        <li class="code-block__container__list__item">{item1}</li>
    ) : null;
    const listItem2 = typeof item2 !== 'undefined' ? (
        <li class="code-block__container__list__item">{item2}</li>
    ) : null;
    const listItem3 = typeof item3 !== 'undefined' ? (
        <li class="code-block__container__list__item">{item3}</li>
    ) : null;
    const listItem4 = typeof item4 !== 'undefined' ? (
        <li class="code-block__container__list__item">{item4}</li>
    ) : null;
    return (
        <div class="code-block">
            <div class="code-block__container">
                <ul class="code-block__container__list">
                    {listItem1}
                    {listItem2}
                    {listItem3}
                    {listItem4}
                </ul>
            </div>
            <div class="code-block__subtext">{subtext}</div>
        </div>
    )

}

export default Codeblock